import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Dropdown, Icon } from 'semantic-ui-react';
import i18next from "i18next";
import { Link } from "react-router-dom";
import {
    STORAGE_BASKET_INDEX,
    DELIVERY_PRICE,
    HIDE_DISCOUNT,
    STORE_DELIVERY,
    PRODUCT_MAX_QUANTITY
} from "../../../utils/constants/variables";
import {
    getRouteByMenuKey,
    getBasketData,
    getLangPrefix,
    getPackDiscountPrice,
    Productoptions,
    getbasketLineItems,
    isExistProductInClickAndCollect,
    getShippingCity,
    setStoreToBasket,
    getConvertedItemDataForGA4,
    getMenuItemByMenuIdElem,
    getMenuItemByProductId,
} from "../../../helpers/helpers";
import Photo from "../../common/product/photo";
import Price from "../../common/product/price";
import { getBrandDetail, getMenu } from "../../../layouts/main/header/redux/selectors";
import { AddToCartActionCreators } from "../../addToCart/redux/actions";
import { bindActionCreators } from "redux";
import BlackWindow from "../../common/blackWindow";
import { getBasket } from "../../addToCart/redux/selectors";
import { replaceComma, getCurrency } from "../../../helpers/helpers";

import closeIcon from "../../../assets/img/close.svg"
import shoppingCartICon from "../../../assets/img/shoppingCartICon_white.svg"
import { getLocationSearch } from "../../locationSearchInput/redux/selectors";
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import StoreLocator from "../../location/container/storeLocator";
import { TrackAddToCart, TrackCartView, TrackRemoveFromCart } from "../../../helpers/ga4Tracker";

import "./basketPopup.scss";
interface Props {
    handleBasketToggle?: any,
    menuList: any,
    basket: any,
    locationSearchData: any,
    brandDetail: any,
}

interface State {
    isChanged: boolean,
    showInfoPopUp: boolean,
    productQTY: string,
    quantityQTY: string | number,
    showStoreLocator: boolean,
    currentQuantity: number,
}

class BasketPopup extends React.Component<Props & typeof AddToCartActionCreators, State> {

    state: State = {
        isChanged: false,
        showInfoPopUp: false,
        showStoreLocator: false,
        productQTY: "",
        quantityQTY: "",
        currentQuantity: 0,
    }

    handleBasketToggle = (show) => {
        this.props.handleBasketToggle(show);
    };
    changeQuantity = (name, value) => {
        const ids = name.split("/");
        this.setState({ isChanged: true });
        this.props.addtocartStart(STORAGE_BASKET_INDEX, ids[0], { size: { quantity: value, productId: ids[1] } }, true);
    }

    handleChangeQuantity = (name, value, currentQuantity = 0) => {
        let { basket } = this.props;
        const ids = name.split("/");
        const storeDelivery = getShippingCity();
        const storeDeliveryType = basket.store && basket.store.storeDelivery || '';

        if (storeDelivery && STORE_DELIVERY === '1') {
            const products = getbasketLineItems();
            products.map(prod => prod.productId === Number(ids[1]) ? prod.quantity = Number(value) : prod);
            this.setState({ quantityQTY: value, productQTY: name });
            const queryParams = storeDeliveryType === 'DIS' ? { shipment: true } : { consignment: true };
            this.props.clickAndCollectStoresStart({
                location: storeDelivery,
                body: { basketLineItems: [...products] },
                queryParams
            });
        } else {
            this.changeQuantity(name, value);
        }

        this.setState({ currentQuantity })

    };
    handleRemoveItem = (productId, sizeElement) => {
        const { basket, getBasketStart } = this.props;

        const { productId: sizeElementProductId } = sizeElement;
        const remItem = basket[productId] || {}
        const basketMenuItem = getMenuItemByMenuIdElem(this.props.menuList, remItem.menuId)
        const dataT = getConvertedItemDataForGA4(this.props.menuItem || basketMenuItem, remItem, sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
        const valueT = dataT.price * dataT.quantity;
        TrackRemoveFromCart(dataT, valueT)

        this.props.removeBasketStart(STORAGE_BASKET_INDEX, productId, sizeElementProductId);
        this.setState({ isChanged: true })

        // call click and collect for delete too
        const storeDelivery = getShippingCity();
        const storeDeliveryType = basket.store && basket.store.storeDelivery || '';
        const queryParams = storeDeliveryType === 'DIS' ? { shipment: true } : { consignment: true };
        const products = getbasketLineItems();

        if (products.length && STORE_DELIVERY === '1') {
            this.props.clickAndCollectStoresStart({
                location: storeDelivery,
                body: { basketLineItems: products },
                queryParams
            }, (data) => {
                const storeCode = basket["store"] && basket["store"]["StoreInfo"] && `${basket["store"]["StoreInfo"].storeCode}`;
                const validStores = data.filter(item => item.storeCode === storeCode || item.shipment && item.hasBasket);
                const validStore = validStores.find(store => store.shipment && store.hasBasket) || validStores[0];
                if (isExistProductInClickAndCollect(validStore.barcodeList, [...products])) {
                    setStoreToBasket({ StoreInfo: validStore, storeDelivery: 'DIS' });
                    getBasketStart();
                }
            });
        }
    };

    setPackElem = (name, value) => {
        this.handleChangeQuantity(name, value)
    };

    componentDidMount = () => {
        const { basket } = this.props
        if (basket && Object.keys(basket).length) {
            const { price } = getBasketData(basket);
            const items: any = [];
            const value = price;
            Object.keys(basket).map((item: any) => {
                if (item !== 'store') {
                    const basketMenuItem = getMenuItemByMenuIdElem(this.props.menuList, basket[item].menuId)
                    basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
                        const dataT = getConvertedItemDataForGA4(basketMenuItem, basket[item], sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
                        items.push(dataT)
                    })
                }
            })
            return TrackCartView(items, value)
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { basket } = this.props;
        if (!prevState.selectedId && this.state.isChanged) {
            this.setState({ isChanged: false });
        }
        if ((this.props.locationSearchData && prevProps.locationSearchData) && this.isStoresChanged(prevProps.locationSearchData, this.props.locationSearchData)) {
            const { quantityQTY, productQTY } = this.state;
            const ids = productQTY.split("/");
            const validStores = this.props.locationSearchData.filter(item => item.shipment && item.hasBasket);
            const products = getbasketLineItems();
            products.map(prod => prod.productId === Number(ids[1]) ? prod.quantity = quantityQTY : prod);
            if (Object.keys(this.props.locationSearchData).length && productQTY && quantityQTY && validStores && validStores.length) {
                const validStore = validStores.find(store => store.shipment && store.hasBasket) || validStores[0];
                if (isExistProductInClickAndCollect(validStore.barcodeList, [...products])) {

                    const product: any = getMenuItemByProductId(basket, +ids[1])
                    if (product && Object.keys(product).length) {
                        const menuItem = getMenuItemByMenuIdElem(this.props.menuList, product.menuId)
                        const isBigger = this.state.currentQuantity > +quantityQTY
                        const quantityDifference = isBigger ? this.state.currentQuantity - +quantityQTY : +quantityQTY - this.state.currentQuantity
                        const dataT = getConvertedItemDataForGA4(menuItem, product, quantityDifference);
                        const valueT = dataT.price * dataT.quantity
                        if (isBigger) TrackRemoveFromCart(dataT, valueT)
                        else TrackAddToCart(dataT, valueT)
                    }

                    this.changeQuantity(productQTY, quantityQTY);
                    this.props.locationSearchDestroyedStart();
                    this.setState({ quantityQTY: "", productQTY: "" });
                } else {
                    this.setState({ showInfoPopUp: true, quantityQTY: "", productQTY: "", currentQuantity: 0 });
                    this.props.locationSearchDestroyedStart();
                }
            } else if (productQTY && quantityQTY) {
                this.setState({ showInfoPopUp: true, quantityQTY: "", productQTY: "", currentQuantity: 0 });
                this.props.locationSearchDestroyedStart();
            }
        }
    }
    toggleStoreLocator = () => this.setState(prevState => ({ ...prevState, showStoreLocator: !prevState.showStoreLocator }));

    handleCloseInfoPopup = () => {
        this.setState({ showInfoPopUp: false, quantityQTY: "", productQTY: "" });
        this.toggleStoreLocator();
    };

    isStoresChanged = (oldStores, newStores) => {
        return oldStores.length !== newStores.length ?
            true :
            newStores.reduce((isChanged, store, index) => {
                if (!oldStores[index] || store.storeCode !== oldStores[index].storeCode) {
                    isChanged = true;
                }
                return isChanged;
            }, false)
    }

    render() {
        const { menuList, basket, brandDetail } = this.props;

        const { totalQuantity, price } = getBasketData(basket);
        const { showInfoPopUp, showStoreLocator } = this.state;
        const { store: { StoreInfo = null, storeDelivery = '' } = {} } = basket;

        let promotionRuleName = "";
         const isTypeExist = (brandDetail && brandDetail.promotion || []).find((item) => ["AMOUNT", "VALUE", "QUANTITY", "PERCENTAGE", "PACK"].includes(item.ruleType));
        if (isTypeExist) promotionRuleName = isTypeExist.ruleName;

        return (
            <Fragment>
                <BlackWindow type="page" handleClick={() => this.handleBasketToggle(false)} />
                <div className="basket-popup active popup_animation">
                    <div className="close" onClick={() => this.handleBasketToggle(false)} />
                    <div className="basket-popup-content">
                        <div className="basket-popup-shop-title">
                            <div className="shoppingBox">
                                <img src={shoppingCartICon} alt="PHOTO" />
                                <h4 className="shop-title">{totalQuantity} {totalQuantity < 2 ? i18next.t('product.basket.22') : i18next.t('product.basket.1')}</h4>
                            </div>
                            <img src={closeIcon} alt="PHOTO" onClick={() => this.handleBasketToggle(false)} />
                        </div>

                        <h4 className="basket-popup-title">
                            {promotionRuleName ?
                                promotionRuleName :
                                StoreInfo && storeDelivery === "STO" ?
                                    `${StoreInfo.storeShortName} (${StoreInfo.zip} ${StoreInfo.city})`
                                    :
                                    DELIVERY_PRICE > 0
                                        ? i18next.t('product.basket.45', {
                                            price: replaceComma(DELIVERY_PRICE),
                                            currency: getCurrency()
                                        })
                                        : i18next.t('product.product.9')

                            }
                        </h4>

                        <div className="articles-content">

                            <div className="d-between">
                                <h4 className="articles-content-title">{totalQuantity} {totalQuantity < 2 ? i18next.t('product.basket.23') : i18next.t('product.basket.12')} </h4>
                                <h4 className="articles-content-price">{i18next.t('product.parure.5')} : <span>{replaceComma(price)}€</span> </h4>
                            </div>
                            <button className="articles-content-button"
                                onClick={() => this.handleBasketToggle(false)}>{i18next.t('product.basket.2')}</button>
                            <p
                                className={`articles-content-button ${Object.keys(basket).length ? "" : "disabled"}`}><Link to={getRouteByMenuKey(menuList, "ma_commande").route}
                                    onClick={() => this.handleBasketToggle(false)}  >
                                    {i18next.t('product.basket.3')}
                                </Link>
                            </p>
                        </div>

                        <div className="product-content">
                            {
                                Object.keys(basket).map((item: any) => (
                                    basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
                                        const { parentElementQuantity, childElementQuantity, totalChildPrice, childElementStartingPrice } = getPackDiscountPrice("basket", basket[item], item)
                                        const isPackElem = basket[item].packParent && childElementQuantity === parentElementQuantity
                                        return (<div className="product-content-item" key={i}>
                                            <div className="product-content-item-img">
                                                <Link onClick={(sum) => this.handleBasketToggle(false)}
                                                    to={`${getLangPrefix()}${basket[item].canonicalMenu}/${basket[item].canonicallUrl}`} >
                                                    <Photo src={basket[item].photo} thumbnail={item.thumbnail} discountDetail={""} />
                                                </Link>
                                            </div>
                                            <div className="product-content-item-body">
                                                <div className="product-content-item-body-title">
                                                    <h4 className="product-content-item-body-title-text">
                                                        <span className="title">{basket[item].name}{storeDelivery === "STO" && !sizeElement.webInventory ? ` (${i18next.t('catalogue.product.9')})` : ""}</span>
                                                        <span>{sizeElement.size}</span>
                                                    </h4>
                                                    {isPackElem ?
                                                        <Icon
                                                            name="trash alternate"
                                                            disabled={isPackElem}
                                                            onClick={
                                                                () => childElementQuantity > parentElementQuantity ?
                                                                    this.setPackElem(`${item}/${sizeElement.productId}`, parentElementQuantity.toString()) :
                                                                    this.handleRemoveItem(item, sizeElement)
                                                            }
                                                        /> :
                                                        <Icon
                                                            name="trash alternate"
                                                            onClick={
                                                                () => parentElementQuantity && childElementQuantity > parentElementQuantity ?
                                                                    this.setPackElem(`${item}/${sizeElement.productId}`, parentElementQuantity.toString()) :
                                                                    this.handleRemoveItem(item, sizeElement)
                                                            }
                                                        />
                                                    }
                                                </div>
                                                <div className="product-content-item-body-footer">
                                                    <div className="product-content-item-body-footer-select">
                                                        <p className="text">{i18next.t('product.basket.summary.2')}</p>
                                                        <Dropdown
                                                            className="mainSelect mini basket"
                                                            value={sizeElement.quantity.toString()}
                                                            options={[...Productoptions(StoreInfo ? PRODUCT_MAX_QUANTITY : sizeElement.webInventory)].filter(elem => !basket[item].packParent || Number(elem.value) >= parentElementQuantity)}
                                                            compact
                                                            selection
                                                            name={`${item}/${sizeElement.productId}`}
                                                            onChange={(e, { value, name }) => this.handleChangeQuantity(name, value, sizeElement.quantity)}
                                                            icon={<Icon name='angle down' />} />
                                                    </div>

                                                        <div className="product-content-item-body-footer-price">
                                                            {
                                                                basket[item].packParent ?
                                                                    <Fragment>
                                                                        {
                                                                            totalChildPrice !== childElementQuantity * childElementStartingPrice && HIDE_DISCOUNT === "0" ?
                                                                            <span className="old-price"> {replaceComma(childElementQuantity * childElementStartingPrice)}</span> : ""
                                                                        }
                                                                        <span className="new-price">{replaceComma(totalChildPrice)}</span>
                                                                    </Fragment>
                                                                    :
                                                                    <Price
                                                                        oldP={sizeElement.oldPrice}
                                                                        startP={sizeElement.startingPrice}
                                                                        discount={sizeElement.discountDetail}
                                                                        newP={sizeElement.listPriceAfterDiscount}
                                                                        quantity={sizeElement.quantity}
                                                                    />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                        })
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
                {showInfoPopUp &&
                    <ConfirmPopup
                        handleCancel={this.handleCloseInfoPopup}
                        text={i18next.t('modal.2')}
                        title={""}
                        footer={false}
                    />
                }
                {showStoreLocator &&
                    <StoreLocator
                        toggleStoreLocator={this.toggleStoreLocator}
                        product={basket}
                        isPayment={true}
                    />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
    basket: getBasket(state),
    locationSearchData: getLocationSearch(state),
    brandDetail: getBrandDetail(state),
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators(AddToCartActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketPopup);
