import React, { Component } from "react";
import i18next from "i18next";
import PaypalBtn from 'react-paypal-checkout';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Checkbox, Icon } from "semantic-ui-react";
import { Link, Redirect, withRouter } from "react-router-dom";

import ResponseMessage from "../../responseMessage/container";
import { PaymentActionCreators } from "../redux/actions";
import {
  getCreateOrder,
  getDeliveryMethod,
  getDiscount,
  getPromotionDiscount,
} from "../../basket/redux/selectors";
import {
  getCurrency,
  getBasketData,
  replaceComma,
  getLangPrefix,
  getConvertedItemDataForGA4,
  tooFixed,
  getLocalStorageObject,
  makeThousandFormat,
  adyenLocalization,
  getMenuItemByMenuIdElem,
} from "../../../helpers/helpers";
import {
  getBrandDetail,
  getMenu,
  getProductClusterPackBarcodes,
  getUser,
} from "../../../layouts/main/header/redux/selectors";
import {
  BRAND_ID,
  COUNTRY,
  DEFAULT_CURRENCY,
  REDEEM_POINTS,
  STORE_DEPOT,
  VALIDATE_DISCCOUNT,
} from "../../../utils/constants/variables";
import {
  getAmount,
  getError,
  getStatus,
  getLoading,
  getEncriptAmount,
  getAdyenSession,
  getAdyenConfiguration,
} from "../redux/selectors";
import { getUserInfo, isAuthenticated } from "../../login/redux/selectors";
import { STORE_DELIVERY } from "../../../utils/constants/variables";
import { getUpdateStatus } from "../../basket/redux/selectors";
import { getPaypalData } from "../../validation/redux/selectors";
import { MenuActionCreators } from "../../../layouts/main/header/redux/actions";
import { StoreLocationActionCreators } from "../../location/redux/actions";
import { OrderActionCreators } from "../../basket/redux/actions";
import { TrackPurchase } from "../../../helpers/ga4Tracker";
import AdyenCheckout from "@adyen/adyen-web";
import DiscountPayment from "../../basket/container/components/discountPayment";
import paypalImage from "../../../assets/img/paypal.png";
import creditCardImage from "../../../assets/img/creditcard.png";

import "./payment.scss";
import "@adyen/adyen-web/dist/adyen.css";

interface Props {
  basket?: any;
  order: any;
  menuList?: any;
  amount: any;
  error: string;
  status: string;
  loading: boolean;
  user: any;
  authenticated: boolean;
  getUpdateStatus: string;
  deliveryMethod: string;
  encriptAmount: string;
  paypalInfo: any;
  payRef?: React.RefObject<any>;
  confirm?: any;
  discount: any;
  promotionDiscount: any;
  detail: any;
  selectedStore?: any;
  shippingAddress?: any;
  webClient: any;
  productClusterBarcodes: any;
}

interface State {
  accept: boolean;
  paymentForm: any;
  showMessage: boolean;
  emptyOrder: boolean;
  isCheOrder: boolean;
  accepted: string;
  showForm: boolean;
  payPalData: any;
  paypalLoading: boolean;
  cgvMenu: {
    canonicalUrl: string;
    label: string;
  };
  orderId: number;
  dropIn: any;
  isPaymentCheckedAllowed: boolean;
  isChecked: boolean;
  loading: any;
  total: number;
  id: number;
  paymentStatus: any;
}

class Payment extends Component<Props & typeof PaymentActionCreators> {
  state: State = {
    accept: false,
    isCheOrder: true,
    paymentForm: {},
    showMessage: false,
    emptyOrder: false,
    accepted: "start",
    showForm: false,
    payPalData: {},
    paypalLoading: false,
    cgvMenu: {
      canonicalUrl: "",
      label: "",
    },
    orderId: 0,
    dropIn: {},
    isPaymentCheckedAllowed: true,
    isChecked: false,
    loading: false,
    total: 0,
    id: 1,
    paymentStatus: "",
  };

  formRef: any = React.createRef();
  paymentContainer: any = React.createRef();

  componentDidMount(): void {
    const { basket } = this.props;
    const { totalPrice } = getBasketData(basket);
    this.setState({ total: totalPrice });

    const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
    if (storeDelivery === "STO") {
      this.props.selectedDeliveryMethodStart("storeDelivery");
    } else {
      this.props.selectedDeliveryMethodStart("onlineDelivery");
    }

    if (Array.isArray(this.props.menuList)) {
      this.setState({
        cgvMenu:
          this.props.menuList.find((item) => item.altUrl1 === "cgv") || {},
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { basket } = this.props;
    if (
      nextProps.amount.eptResponse &&
      (!this.props.amount.eptResponse ||
        (this.props.amount.eptResponse &&
          this.props.amount.eptResponse.mac !==
            nextProps.amount.eptResponse.mac))
    ) {
      this.setState({ paymentForm: nextProps.amount }); //Show Paypal button, don't redirect directly to Monetico
      const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
      if (storeDelivery === "STO") {
        this.props.selectedDeliveryMethodStart("storeDelivery");
      } else {
        this.props.selectedDeliveryMethodStart("onlineDelivery");
      }
    }

    if (nextProps.status && nextProps.status !== this.props.status) {
      setTimeout(() => {
        this.setState({ showMessage: false });
      }, 7000);
    }

    if (
      nextProps.order &&
      nextProps.order.length &&
      ["AWA", "CHE"].includes(nextProps.order[0].orderStatus) &&
      (!this.props.order[0] ||
        nextProps.order[0].orderId !== this.props.order[0].orderId)
    ) {
      this.setState({ isCheOrder: false });
    }
    if (this.props.getUpdateStatus !== nextProps.getUpdateStatus) {
      if (nextProps.getUpdateStatus === "success") {
        this.setState({ accepted: "success" });
      } else if (nextProps.getUpdateStatus === "failed") {
        this.setState({ accepted: "failed" });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      basket,
      order,
      deliveryMethod,
      totalPrice: totalPriceToProps,
      webClient,
    } = this.props;
    const { totalPrice } = getBasketData(basket);
    const { accept, isCheOrder } = this.state;
    const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
    const disabled =
      !accept ||
      isCheOrder ||
      !(order && order[0]) ||
      (storeDelivery === "STO" &&
        deliveryMethod === "storeDelivery" &&
        STORE_DEPOT === "1" &&
        STORE_DELIVERY === "1");

    if (this.props.userStore !== prevProps.userStore) {
      this.setState({ accept: false });
    }

    if (prevProps.order[0] && !this.props.order[0]) {
      this.setState({ paymentForm: {} });
    }

    if (this.props.adyenSession !== prevProps.adyenSession) {
      this.adyenCall(this.props.adyenSession, order);
    }

    const orderId =
      (order && order.length && order[0].orderId) || this.state.id;

    if (this.state.id !== orderId && !disabled) {
      const [orderOne] = order;
      !disabled && this.setState({ orderId: orderOne.orderId });
      this.handleSession(orderOne.orderId);
      if (!this.state.loading && totalPrice) {
        this.setState({ loading: true });
      }
      this.setState({ total: totalPrice });
      this.setState({ id: order[0].orderId });
    }

    if (!disabled && totalPrice !== this.state.total) {
      this.setState({ total: totalPrice });
      const { price } = getBasketData(basket);
      const items = [] as any;
      const vDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT);
      const { value = 0 } = vDiscount;
      const dValue = tooFixed(value > 0 ? price - price / value : price, 2);
      Object.keys(basket).map((item: any) => {
        if (item !== "store") {
          const basketMenuItem = getMenuItemByMenuIdElem(
            this.props.menuList,
            basket[item].menuId
          );
          basket[item].sizes &&
            basket[item].sizes.length &&
            basket[item].sizes.map((sizeElement, i) => {
              const dataT = getConvertedItemDataForGA4(
                this.props.menuItem || basketMenuItem,
                basket[item],
                sizeElement.quantity && sizeElement.quantity > 0
                  ? sizeElement.quantity
                  : 1,
                sizeElement
              );
              dataT.location_id =
                localStorage.getItem("selectedPaymentMethod") || "card";
              items.push(dataT);
            });
        }
      });
      TrackPurchase(items, dValue);
      this.props.confirm();
    }
  }

  componentWillUnmount() {
    this.setState({
      accept: false,
      isCheOrder: true,
      paymentForm: {},
      showMessage: false,
      emptyOrder: false,
      accepted: "start",
      showForm: false,
      payPalData: {},
      paypalLoading: false,
      loading: false,
      id: 1,
      total: 0,
    });
    localStorage.removeItem("selectedPaymentMethod");
  }

  handleSession = (orderId) => {
    const { basket, detail } = this.props;
    const { dropIn } = this.state;
    const { totalPrice } = getBasketData(basket);

    dropIn.unmount && dropIn.unmount();

    if (!totalPrice) {
      this.setState({ loading: false });
      this.setState({ accept: false });
      return;
    }

    // const amount = getRealNumber(totalPrice, 2)

    const body = {
      orderId,
      country: COUNTRY,
      currency: DEFAULT_CURRENCY,
      returnUrl: "/",
      shopperLocale: adyenLocalization(detail.lowerlanguage),
    };

    this.props.adyenSessionStart(body);
  };

  adyenCall = async (session: any = this.props.adyenSession, order: any) => {
    const { history, adyenConfiguration } = this.props;
    const configuration = {
      ...adyenConfiguration,
      session: { ...session },
      onPaymentCompleted: (result) => {
        if (result.resultCode === "Refused" || result.resultCode === "Error") {
          console.log(result, 1);
          // history.push(`${getLangPrefix()}/failed`);
        } else {
          history.push(`${getLangPrefix()}/accepted`);
        }
      },
      onError: (error) => {
        if (!error.toString().includes("CANCEL")) {
          console.log(error, 2);
          // history.push(`${getLangPrefix()}/failed`);
        }
      },
    };

    const checkout = await AdyenCheckout(configuration);

    if (this.paymentContainer.current) {
      const dropIn = checkout
        .create("dropin", {
          onReady: () => {
            // Event to change selected payment method on click Paypal button
            const cardButton = document.querySelector(
              ".adyen-checkout__payment-method--card"
            );
            if (cardButton) {
              cardButton.addEventListener("click", () => {
                localStorage.setItem("selectedPaymentMethod", "card");
              });
            }

            // Event to change selected payment method on click Paypal button
            const paypalButton = document.querySelector(
              ".adyen-checkout__payment-method--paypal"
            );
            if (paypalButton) {
              paypalButton.addEventListener("click", () => {
                localStorage.setItem("selectedPaymentMethod", "paypal");
              });
            }
            this.setState({ loading: false });
          },
        })
        .mount(this.paymentContainer.current);
      this.setState({ dropIn });
    }
  };

  hendleChangeCeckBoxCheked = (event, input) => {
    if (input.checked) {
      const isConfirm = this.props.confirm();
      isConfirm && this.setState({ loading: true });
    } else {
      this.setState({ orderId: 0 });
    }
    this.setState({ accept: !this.state.accept });
  };

  removeValidateDiscount = () => {
    localStorage.removeItem("validateDiscount");
    this.props.annullerDiscountStart();
    this.props.getBasketStart();
  };

  render() {
    const {
      basket,
      status,
      error,
      order,
      deliveryMethod,
      promotionDiscount,
      detail,
      webClient,
      discount,
    } = this.props;
    const { loyaltyPoints, loyaltyValue, currency } =
      webClient.storeClientLoyalty || {
        loyaltyPoints: -Infinity,
        loyaltyValue: -Infinity,
        currency: "EUR",
      };
    const treshold = detail.conversionThreshold || Infinity;
    const mmultiple = detail.conversionMultiple || 0;
    const {
      accept,
      showMessage,
      isCheOrder,
      cgvMenu,
      paymentStatus,
    } = this.state;
    const validateDiscount = discount;
    const {
      price,
      totalPrice,
      livraison,
      totalQuantity,
      oldPrice,
      discountedSum,
    } = getBasketData(basket);
    const isStoreDeliveryMethod = deliveryMethod === "storeDelivery";
    const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];

    if (paymentStatus === "Authorised" && paymentStatus !== "Cancelled") {
      return <Redirect to={{ pathname: `${getLangPrefix()}/accepted` }} />;
    }

    if (paymentStatus === "Refused" && paymentStatus !== "Cancelled") {
      return <Redirect to={{ pathname: `${getLangPrefix()}/failed` }} />;
    }

    const disabled =
      !totalPrice ||
      !accept ||
      isCheOrder ||
      !(order && order[0]) ||
      (storeDelivery === "STO" &&
        deliveryMethod === "storeDelivery" &&
        STORE_DEPOT === "1" &&
        STORE_DELIVERY === "1");
    const checkFooterLine =
      (!isStoreDeliveryMethod && totalPrice - oldPrice) || "";
    const ruleNameMessage =
      detail &&
      detail.promotion &&
      detail.promotion.length &&
      detail.promotion[0] &&
      detail.promotion[0].ruleName &&
      detail.promotion[0].ruleName;
    const loyaltyPointsCalculated = makeThousandFormat(
      Math.trunc(loyaltyPoints / mmultiple) * mmultiple
    );
    const promotion = detail && detail.promotion && detail.promotion.length && detail.promotion[0];
    const isPercentPromotion = ["AMOUNT", "QUANTITY", "PERCENTAGE"].includes(promotion && promotion.ruleType);

    return (
      <div className="peymant-content payment-section active">
        <div>
          <div className="dflex justify-content-between flex-column peymant-content-item-box">
            <DiscountPayment classN={"mobileSize768 mb_10"} />
            {REDEEM_POINTS === "1" && loyaltyPoints >= treshold && (webClient && webClient.brandId) === BRAND_ID && (
              <div className="peymant-content-item peymant-content-item-value">
                <div className="peymant-content-item-value-wrapper">
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                    <h4>
                      {i18next.t("mesInformations.23")}:&nbsp;
                      <span className="price">{loyaltyPoints}</span>
                    </h4>
                  </div>
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                    <h4>
                      {i18next.t("product.basket.42")}:&nbsp;
                      <span className="price">
                        {loyaltyPointsCalculated || ""}
                      </span>
                    </h4>
                  </div>
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between close">
                    {price < loyaltyValue ? (
                      <h4>
                        ({i18next.t("product.basket.44")}:{" "}
                        <span className="price">
                          {replaceComma(loyaltyValue)}
                          {getCurrency(currency)}
                        </span>
                        )
                      </h4>
                    ) : (
                      <h4>
                        ({i18next.t("product.basket.43")}:{" "}
                        <span className="price">{mmultiple}</span>)
                      </h4>
                    )}
                  </div>
                  <div className="d-center">
                    <button
                      className={`valider-button hoverButtonStyle ${
                        price < loyaltyValue ? "disabled" : ""
                      }`}
                      onClick={() => this.props.createClientDiscountStart({})}
                      disabled={price < loyaltyValue}
                    >
                      {i18next.t("product.basket.11")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="peymant-content-item peymant-content-item-value">
              <div className="peymant-content-item-value-wrapper">
                <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                  <h4>
                    {totalQuantity}{" "}
                    {totalQuantity < 2
                      ? i18next.t("product.basket.23")
                      : i18next.t("product.basket.12")}
                  </h4>
                  {isStoreDeliveryMethod ? (
                    <h2 className="price">
                      {" "}
                      {replaceComma(price) + getCurrency()}
                    </h2>
                  ) : (
                    <h2 className="price">
                      {" "}
                      {replaceComma(price)}
                      {getCurrency()}
                    </h2>
                  )}
                </div>

                {validateDiscount.value && !validateDiscount.isProductsInBarcode && (
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                    <div className="validateDiscountWrapper">
                      <h4>
                        {" "}
                        {validateDiscount
                          ? i18next.t("product.basket.21") +
                            " " +
                            `${validateDiscount.discountLabel}`.toUpperCase()
                          : ""}
                      </h4>
                      <span
                        onClick={this.removeValidateDiscount}
                        className="validateDiscount-trashicon"
                      >
                        <Icon className="darkred" name="trash alternate" />
                      </span>
                    </div>
                    <h2 className="price">
                      -
                      {validateDiscount &&
                      validateDiscount.couponType === "PERCENT" &&
                      replaceComma(validateDiscount.value).split(",")[1] ===
                        "00"
                        ? replaceComma(validateDiscount.value).split(",")[0]
                        : validateDiscount
                        ? replaceComma(validateDiscount.value)
                        : ""}
                      {validateDiscount.couponType === "PERCENT" ? "%" : "€"}
                    </h2>
                  </div>
                )}
                {discountedSum ? (
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                    <h4>{ruleNameMessage}</h4>
                    <h2 className="price">
                      -{isPercentPromotion ? discountedSum : replaceComma(discountedSum)}
                      {isPercentPromotion ? "%" : "€"}
                    </h2>
                  </div>
                ) : (
                  ""
                )}
                {!isStoreDeliveryMethod ? (
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                    <h4>
                      {livraison
                        ? i18next.t("product.basket.18")
                        : i18next.t("product.basket.17")}
                    </h4>
                    <h2 className="price">
                      {" "}
                      {replaceComma(livraison) + getCurrency()}
                    </h2>
                  </div>
                ) : (
                  ""
                )}
                {totalPrice - oldPrice ? (
                  <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                    <h4>{i18next.t("product.basket.27")}</h4>
                    <h2 className="price">
                      {" "}
                      {replaceComma(totalPrice - oldPrice) + getCurrency()}
                    </h2>
                  </div>
                ) : null}
              </div>
              <div
                className={`peymant-content-item-value-footer dflex justify-content-between ${
                  checkFooterLine && "p-20"
                }`}
              >
                <h4>
                  {STORE_DEPOT === "1"
                    ? i18next.t("product.basket.15")
                    : storeDelivery === "STO"
                    ? i18next.t("product.basket.31")
                    : i18next.t("product.basket.15")}
                </h4>
                <div>
                  <h4 className="peymant-content-all-price">
                    {replaceComma(totalPrice) + getCurrency()}
                  </h4>
                  <h4 className="comprise">{i18next.t("product.basket.16")}</h4>
                </div>
              </div>
            </div>
            <div
              className="peymant-content-item peymant-content-item-code"
              ref={this.props.payRef}
            >
              <h4 className={"text-uppercase"}>{i18next.t("payment.1")}</h4>
              <Checkbox
                onChange={this.hendleChangeCeckBoxCheked}
                className="mainCheckbox"
                checked={accept}
                label={
                  <label>
                    {i18next.t("payment.2")}
                    <Link
                      className={"main-link ml_15"}
                      to={getLangPrefix() + cgvMenu.canonicalUrl}
                      target="_blank"
                    >
                      {cgvMenu.label}
                    </Link>
                  </label>
                }
              />
              {showMessage && status && (
                <ResponseMessage
                  status={status}
                  errorText={error}
                  successText="success"
                />
              )}

              <div>
                {this.state.loading && (
                  <div className="loader-wrapper">
                    <div className="loader" />
                  </div>
                )}
                {disabled ? (
                  <>
                    <div
                      style={{ display: this.state.loading ? "none" : "block" }}
                    >
                      <div className="payment-buttons">
                        {/*<button className="google-pay-button">*/}
                        {/*    <img*/}
                        {/*        className="google-pay-button-image"*/}
                        {/*        src={googlePayImage}*/}
                        {/*        alt="googlepay"*/}
                        {/*    />*/}
                        {/*</button>*/}
                        <button className="credit-card-button">
                          <div className="inner-credit-card-button">
                            <img
                              className="credit-card-button-image"
                              src={creditCardImage}
                              alt="credit card"
                            />
                            <span>{i18next.t("payment.15")}</span>
                          </div>
                        </button>
                        <button className="paypal-button">
                          <img
                            className="paypal-button-image"
                            src={paypalImage}
                            alt="paypal"
                          />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div id="dropin-container" ref={this.paymentContainer} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any): any => {
  return {
    menuList: getMenu(state),
    order: getCreateOrder(state),
    amount: getAmount(state),
    authenticated: isAuthenticated(state),
    error: getError(state),
    status: getStatus(state),
    loading: getLoading(state),
    user: getUser(state),
    getUpdateStatus: getUpdateStatus(state),
    deliveryMethod: getDeliveryMethod(state),
    encriptAmount: getEncriptAmount(state),
    paypalInfo: getPaypalData(state),
    discount: getDiscount(state),
    promotionDiscount: getPromotionDiscount(state),
    detail: getBrandDetail(state),
    adyenConfiguration: getAdyenConfiguration(state),
    adyenSession: getAdyenSession(state),
    webClient: getUserInfo(state),
    productClusterBarcodes: getProductClusterPackBarcodes(state),
  };
};

const mapDispatchToProps = (
  dispatch
): typeof PaymentActionCreators | typeof MenuActionCreators => {
  return bindActionCreators(
    Object.assign(
      {},
      MenuActionCreators,
      PaymentActionCreators,
      StoreLocationActionCreators,
      OrderActionCreators
    ),
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment));
