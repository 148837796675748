import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ValidationActionCreators } from "../../validation/redux/actions";
import {
  getPaymentData,
  getValidation,
} from "../../validation/redux/selectors";
import { getError, getMenu } from "../../products/redux/selectors";
import {
  getCreateOrder,
  getUpdateStatus,
  getUser,
} from "../../basket/redux/selectors";
import { getBasket } from "../../addToCart/redux/selectors";
import i18next from "i18next";
import {
  addDays,
  dateStringFormat,
  getBasketData,
  getConvertedItemDataForGA4,
  getDataBySectionAndWidget,
  getLivrasionEstimate,
  getLocalStorageObject,
  getMenuItemByMenuIdElem,
  getRouteByMenuKey,
  tooFixed,
} from "../../../helpers/helpers";
import { Link, Redirect } from "react-router-dom";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { getUserStore } from "../../location/redux/selectors";
import {
  ORDER_STATUS,
  STORAGE_BASKET_INDEX,
  STORE_DELIVERY,
  STORE_DEPOT,
  VALIDATE_DISCCOUNT,
} from "../../../utils/constants/variables";
import { getAdyenSession } from "../../payment/redux/selectors";
import { TrackPurchase } from "../../../helpers/ga4Tracker";

interface Props {
  data: any;
  error: any;
  menuList: any;
  paymentData: any;
  updateStatus: any;
  user: any;
  basket: any;
  order: any;
  updateOrderStart: any;
  validationPage: any;
  validationStart: any;
  location: any;
  BaskstStore: any;
  userStore: any;
  adyenSession: any;
}

const PaymentConfirmation: React.FC<Props> = ({
  data,
  menuList,
  order,
  user,
  validationPage,
  validationStart,
  updateOrderStart,
  location,
  basket,
  userStore,
  adyenSession,
}) => {
  const [storeDelivery, setStoreDelivery] = useState<string>("");
  const [selectedStore, setSelectedStore] = useState<any>("");

  const orderId = (order && order.length && order[0].orderId) || null;
  const storeDel = basket["store"] && basket["store"]["storeDelivery"];
  const BaskstStore = storeDel ? basket["store"] : "";
  const store = selectedStore ? selectedStore : userStore;
  const isFailed = location.pathname.includes("failed");

  useEffect(() => {
    const selectedStore =
      BaskstStore && BaskstStore["storeDelivery"]
        ? BaskstStore["storeDelivery"]
        : "";
    const storeType =
      BaskstStore &&
      BaskstStore["StoreInfo"] &&
      BaskstStore["StoreInfo"]["storeType"];
    let orderStatus = ORDER_STATUS.PAI;
    if (selectedStore !== ORDER_STATUS.DIS || storeType !== "E") {
      if (!isFailed) {
        if (selectedStore) {
          if (STORE_DEPOT === "1" && STORE_DELIVERY === "0") {
            orderStatus = ORDER_STATUS.DEP;
          } else if (!(STORE_DEPOT === "1" && STORE_DELIVERY === "1")) {
            orderStatus = selectedStore;
          }
        }
      } else {
        orderStatus = ORDER_STATUS.FAI;
      }
    }
    orderId && updateOrderStart({ orderId, orderStatus, email: user.email });
    if (
      [
        ORDER_STATUS.PAI,
        ORDER_STATUS.DIS,
        ORDER_STATUS.STO,
        ORDER_STATUS.DEP,
      ].includes(orderStatus)
    ) {

      const { price } = getBasketData(basket);
      const items = [] as any
      const vDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT)
      const {value = 0} = vDiscount
      const dValue = tooFixed(value > 0 ? price - (price / value) : price, 2)

      Object.keys(basket).map((item: any) => {
          if (item !== 'store') {
              const basketMenuItem = getMenuItemByMenuIdElem(menuList,basket[item].menuId)
              basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
                  const dataT = getConvertedItemDataForGA4(basketMenuItem, basket[item], sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
                  dataT.location_id = localStorage.getItem('selectedPaymentMethod') || 'card'
                  items.push(dataT)
              })
          }
      })
      TrackPurchase(items, dValue)

      localStorage.removeItem(STORAGE_BASKET_INDEX);
      localStorage.removeItem(VALIDATE_DISCCOUNT);
      localStorage.removeItem("freight_cost");
      localStorage.removeItem('selectedPaymentMethod')
      localStorage.setItem('isAnulled', 'anulled');
    }
  }, [orderId]);

  useEffect(() => {
    const locationBasket = location && location.state && location.state.BaskstStore;
    setStoreDelivery(
      locationBasket
        ? locationBasket["storeDelivery"]
        : BaskstStore
          ? BaskstStore["storeDelivery"]
          : ""
    );
    setSelectedStore(
      locationBasket
        ? locationBasket["StoreInfo"]
        : BaskstStore
          ? BaskstStore["StoreInfo"]
          : ""
    )
  }, [])

  useEffect(() => {
    validationPage &&
      validationPage.gridId &&
      validationStart(validationPage.gridId);
  }, [validationPage]);

  if (orderId === null) {
    return <Redirect to={"/"} />;
  }

  return (
    <div>
      <div className="small-container">
        <div className="validation">
          {isFailed &&
          <>
            <h2>{i18next.t("validation.15")}</h2>
            <h4 className="validation-title">{i18next.t("validation.16")}</h4>
            <h4>{i18next.t("validation.17")}</h4>
          </>
          }
          {!isFailed &&
          <>
            <h2>{i18next.t("validation.1")}</h2>
            <h4 className="validation-title">{i18next.t("validation.2")}</h4>
            <h4>{i18next.t("validation.3")}</h4>
            <p>
              {i18next.t("validation.4")}:
              <span className="bold-text"> {orderId}</span>
            </p>
            {/*{adyenSession.reference && <p>{i18next.t('validation.14')} : <span*/}
            {/*                className="bold-text">{adyenSession.reference}</span></p>}*/}
            {storeDelivery !== 'STO' && <p>{i18next.t('payment.16')}: <span className="livrasion-estimate-text">{getLivrasionEstimate()}</span></p>}
            {(storeDelivery === "STO" || "") && (
              <p>
                  {i18next.t('locator.8')}
                  <span className="bold-text">{` ${dateStringFormat("", addDays(new Date(), 1))} 14h`}</span>
              </p>
            )}
          </>
          }
        </div>
        {
          <div className="validation storeDetals">
            {!isFailed && storeDelivery === 'STO' &&  (
              <>
                <p className="storeName">{store.name}</p>
                <p>{store.address}</p>
                <p>{store.zip}</p>
                {store.phone ? <p>Tél: {store.phone}</p> : ""}
              </>
            )}
            <Link to="/">{i18next.t("validation.6")}</Link>
          </div>
        }

        <div className="validation-item-content">
          {!isFailed ? <h4>{i18next.t("validation.7")}</h4> : ""}
          <div className="dflex justify-content-between">
            <Link
              className="validation-item--parent"
              to={getRouteByMenuKey(menuList, "notre_savoir_faire").route}
            >
              <div className="validation-item">
                <img
                  src={`${BASE_API_GATEWAY}${
                    getDataBySectionAndWidget(1, 1, data, "PHOTO") &&
                    getDataBySectionAndWidget(1, 1, data, "PHOTO").value
                  }`}
                  alt="validation image"
                />
                <div className="validation-item-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: getDataBySectionAndWidget(1, 2, data).value,
                    }}
                  ></p>
                </div>
              </div>
            </Link>

            <Link
              className="validation-item--parent"
              to={getRouteByMenuKey(menuList, "nouveaux_precieux").route}
            >
              <div className="validation-item">
                <img
                  src={`${BASE_API_GATEWAY}${
                    getDataBySectionAndWidget(2, 1, data, "PHOTO") &&
                    getDataBySectionAndWidget(2, 1, data, "PHOTO").value
                  }`}
                  alt="validation image"
                />
                <div className="validation-item-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: getDataBySectionAndWidget(2, 2, data).value,
                    }}
                  ></p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any): any => {
  const validationPage = state.menuR.menu.length
    ? state.menuR.menu.find((item) => item.key == "paiement")
    : {};

  return {
    data: getValidation(state),
    error: getError(state),
    validationPage: validationPage,
    menuList: getMenu(state),
    paymentData: getPaymentData(state),
    updateStatus: getUpdateStatus(state),
    user: getUser(state),
    basket: getBasket(state),
    order: getCreateOrder(state),
    userStore: getUserStore(state),
    adyenSession: getAdyenSession(state),
  };
};

const mapDispatchToProps = (dispatch): typeof ValidationActionCreators => {
  return bindActionCreators(ValidationActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfirmation);
