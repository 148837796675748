import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18next from "i18next";

import { IValidationState } from "../redux/reducers";
import { ValidationActionCreators } from "../redux/actions";
import { getError, getValidation, getPaymentData } from "../redux/selectors";
import { getRouteByMenuKey, getDataBySectionAndWidget, dataLayerPush, getLivrasionEstimate, getBasketData, getLocalStorageObject, tooFixed, getMenuItemByMenuIdElem, getConvertedItemDataForGA4 } from "../../../helpers/helpers";

import "./validation.scss";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { Link } from "react-router-dom";
import { getMenu, getUser } from "../../../layouts/main/header/redux/selectors";
import Basket from "../../basket/container/basket";
import { getUpdateStatus } from "../../basket/redux/selectors";
import { DISCOUNT_CODE, STORAGE_BASKET_INDEX, VALIDATE_DISCCOUNT } from "../../../utils/constants/variables";
import Acceped from "../../accepted/container";
import { getBasket } from "../../addToCart/redux/selectors";
import { AddToCartActionCreators } from "../../addToCart/redux/actions";
import { TrackPurchase } from "../../../helpers/ga4Tracker";

interface Props extends IValidationState {
    data: any,
    error: any,
    validationPage: any,
    menuList: any,
    paymentData: any,
    updateStatus: string,
    user: any,
    basket: any
}

class Validation extends React.Component<Props & typeof ValidationActionCreators> {
    state = {
        BaskstStore: {},
        isVisible: false
    }
    componentDidMount() {
        if (this.props.moneticoCallbackStatus) {
            const { match } = this.props;
            if (this.props.moneticoCallbackStatus === "accepted") {
                this.props.validationPaymentAcceptedStart(match.params.textlibre);
            } else {
                this.props.validationPaymentFailedStart(match.params.textlibre);
            }
        }

        if (this.props.validationPage && this.props.validationPage.gridId) {
            this.props.validationStart(this.props.validationPage.gridId)
        }
        window.scrollTo(0, 0)
    }

    isPaymentConfirm = (data) => {
        return data.status === "PAYMENT_CONFIRMED"
            || data.status === "PAYMENT_ACKNOWLEDGED_LATE_ORDER_CONFIRMATION_PENDING"
            || data.status === "PAYMENT_ACKNOWLEDGED_AFTER_FAILURES_ORDER_CONFIRMATION_PENDING"
            || data.status === "PAYMENT_ACKNOWLEDGED";
    }

    isPaymentFail = (data) => {
        return data.status === "PAYMENT_FAILED" || data.status === "PAYMENT_ERROR";

    }

    isPaymentAwait = (data) => {
        return data.status === "PAYMENT_ACKNOWLEDGMENT_AWAITING";

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.validationPage && nextProps.validationPage.gridId !== this.props.validationPage.gridId) {
            this.props.validationStart(nextProps.validationPage.gridId);
        }

        if (
            !nextProps.updateStatus
            && nextProps.paymentData
            && nextProps.paymentData.moneticoCallbackStatus === "accepted" &&
            nextProps.paymentData.moneticoCallbackStatus !== this.props.paymentData.moneticoCallbackStatus
            && nextProps.paymentData.status === "SUCCESS" && nextProps.paymentData.status !== this.props.paymentData.status
            && (
                this.isPaymentConfirm(nextProps.paymentData.paymentConfirmDTO)
                || this.isPaymentFail(nextProps.paymentData.paymentConfirmDTO)
                || this.isPaymentAwait(nextProps.paymentData.paymentConfirmDTO)
            )
        ) {
            const orderId = nextProps.paymentData.paymentConfirmDTO.orderId;
            const { user, basket } = nextProps;
            const isFailed = window.location.pathname.includes('failed');
            const status = basket && basket.store && basket.store.storeDelivery || '';
            const isPai = Boolean(
                basket && basket.store && basket.store.StoreInfo ? basket.store.StoreInfo.storeType === "E" : false
            );

            let orderStatus = isPai ? "PAI" : status;
            orderStatus = isFailed ? "FAI" : orderStatus;

            if (orderStatus !== "FAI") {
                dataLayerPush(orderId, user && user.email)
            }

            nextProps.updateOrderStart({
                orderId,
                orderStatus,
                email: nextProps.user.email
            })
            window.scrollTo(0, 0);
            if (orderStatus === "PAI" || status === "DIS" || status === "STO" || status === "DEP") {
                const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
                this.setState({ BaskstStore: storeDelivery ? basket["store"] : "" });
                if (this.props.moneticoCallbackStatus !== "failed") {
                    const { price } = getBasketData(basket);
                    const items = [] as any
                    const vDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT)
                    const { value = 0 } = vDiscount
                    const dValue = tooFixed(value > 0 ? price - (price / value) : price, 2)

                    Object.keys(basket).map((item: any) => {
                        if (item !== 'store') {
                            const basketMenuItem = getMenuItemByMenuIdElem(this.props.menuList, basket[item].menuId)
                            basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
                                const dataT = getConvertedItemDataForGA4(this.props.menuItem || basketMenuItem, basket[item], sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
                                dataT.location_id = localStorage.getItem('selectedPaymentMethod') || 'card'
                                items.push(dataT)
                            })
                        }
                    })
                    TrackPurchase(items, dValue)

                    localStorage.removeItem(STORAGE_BASKET_INDEX);
                    localStorage.removeItem(VALIDATE_DISCCOUNT);
                    localStorage.removeItem(DISCOUNT_CODE);
                    localStorage.removeItem('isAnulled');
                    localStorage.removeItem('selectedPaymentMethod')
                    localStorage.removeItem("freight_cost");
                    this.props.destroyBasketStart();
                }
            }
        }
    }

    render() {
        const { data, menuList, paymentData } = this.props;
        const { BaskstStore, isVisible } = this.state;
        const isPaymentConfirm = this.isPaymentConfirm(paymentData.paymentConfirmDTO || {});
        let menuItem = { "key": "ma_commande" };
        const search = this.props.history && this.props.history.location && this.props.history.location.search;
        const status = search ? search.split("=")[1] : "";
        return (
            <div>
                {
                    paymentData
                        && paymentData.moneticoCallbackStatus === "accepted"
                        && paymentData.status === "SUCCESS"
                        && (
                            isPaymentConfirm
                            || this.isPaymentFail(paymentData.paymentConfirmDTO)
                        )
                        ? status !== "DEP" ?
                            <div className="small-container">
                                {/*<PageMetaData pageKey="validation"/>*/}
                                <div className="validation" >
                                    <h2>{isPaymentConfirm ? i18next.t('validation.1') : i18next.t('validation.15')}</h2>
                                    <h4 className="validation-title">{isPaymentConfirm ? i18next.t('validation.2') : i18next.t('validation.16')}</h4>
                                    <h4>{isPaymentConfirm ? i18next.t('validation.3') : i18next.t('validation.17')}</h4>
                                    <p>{i18next.t('validation.4')} : <span
                                        className="bold-text">{paymentData.paymentConfirmDTO.orderId}</span></p>
                                    {
                                        isPaymentConfirm ?
                                            <p>{i18next.t('validation.13')} :
                                                <span className="bold-text">
                                                    {paymentData.paymentConfirmDTO.transactionId}
                                                </span>
                                            </p> : ""
                                    }
                                    {paymentData.paymentConfirmDTO.reference && <p>{i18next.t('validation.14')} : <span
                                        className="bold-text">{paymentData.paymentConfirmDTO.reference}</span></p>}
                                    {isVisible && <p>{i18next.t('payment.16')}: <span className="livrasion-estimate-text">{getLivrasionEstimate()}</span></p>}

                                    <Link to="/">{i18next.t('validation.6')}</Link>
                                </div>

                                <div className="validation-item-content">
                                    {
                                        isPaymentConfirm ? <h4>{i18next.t('validation.7')}</h4> : ""
                                    }
                                    <div className="dflex justify-content-between">
                                        <Link className="validation-item--parent" to={getRouteByMenuKey(menuList, "notre_savoir_faire").route}>
                                            <div className="validation-item">
                                                <img
                                                    src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(1, 1, data, "PHOTO") && getDataBySectionAndWidget(1, 1, data, "PHOTO").value}`}
                                                    alt="validation image" />
                                                <div className="validation-item-text">
                                                    <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(1, 2, data).value }}></p>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link className="validation-item--parent" to={getRouteByMenuKey(menuList, "nouveaux_precieux").route}>
                                            <div className="validation-item">
                                                <img
                                                    src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(2, 1, data, "PHOTO") && getDataBySectionAndWidget(2, 1, data, "PHOTO").value}`}
                                                    alt="validation image" />
                                                <div className="validation-item-text">
                                                    <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 2, data).value }}></p>
                                                </div>
                                            </div>
                                        </Link>

                                        {/*{*/}
                                        {/*images && images.map((item, i) => (*/}
                                        {/*<div className="validation-item" key={i}>*/}
                                        {/*<img src={BASE_API_GATEWAY + item.value} alt="validation image" />*/}
                                        {/*<div className="validation-item-text">*/}
                                        {/*<p dangerouslySetInnerHTML={{ __html: getData(item.name, 'text', data) }} />*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        {/*))*/}
                                        {/*}*/}
                                    </div>
                                </div>
                            </div> : status === "DEP" || status === "STO" ?
                                <Acceped ID={paymentData.paymentConfirmDTO.orderId} BaskstStore={BaskstStore} /> : "" : ""
                }

                {/*//Awaiting payment confirmation from Monetico on the backend - we need to wait for callback to confirm. TODO for Anton: Here we need to call the backend again after sleeping for few seconds*/}
                {
                    paymentData && paymentData.moneticoCallbackStatus === "accepted" && paymentData.status === "SUCCESS" && this.isPaymentAwait(paymentData.paymentConfirmDTO) &&
                    <h4> We are awaiting payment confirmation from our payment provide. Please wait
                        - {paymentData.message} </h4>
                }
                {/*//Payment failed. Reject the order. display message in paymentData.message*/}
                {
                    paymentData && paymentData.moneticoCallbackStatus === "accepted" && paymentData.status === "SUCCESS" && paymentData.paymentConfirmDTO.status === "PAYMENT_FAILED" &&
                    <Basket menuItem={menuItem} validation={true} />
                }

                {/*//Payment error. Order payment is in invalid state. Manual intervention required to resolve the issue.*/}
                {/*{  */}
                {/*    paymentData && paymentData.moneticoCallbackStatus === "accepted" && paymentData.status === "SUCCESS" && paymentData.paymentConfirmDTO.status === "PAYMENT_ERROR" &&*/}
                {/*    <Basket menuItem={menuItem} />*/}
                {/*}*/}

                {/*//Backend unable to process the acceptance callback from Monetico - Take user to shopping cart  and display message*/}
                {
                    paymentData && paymentData.moneticoCallbackStatus === "accepted" && paymentData.status === "ERROR" &&
                    <h4> We are not able to complete your order. Please contact customer
                        service. {paymentData.message} </h4>
                }

                {/*// Monetico payment failed and we have recorded it successfully on the backend - Take user to shopping cart */}
                {
                    paymentData && paymentData.moneticoCallbackStatus === "failed" && paymentData.status === "SUCCESS" &&
                    <Basket menuItem={menuItem} validation={true} />
                }
                {/*// Backend unable to process the failed message callback from Monetico - Take user to shopping cart and display message*/}
                {
                    paymentData && paymentData.moneticoCallbackStatus === "failed" && paymentData.status === "ERROR" &&
                    <h4> Payment failed and we are not able to complete your order. Please contact customer
                        service. {paymentData.message}</h4>
                }
                {
                    !paymentData &&
                    <h4> Please wait while we validate the payment </h4>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const validationPage = state.menuR.menu.length ? state.menuR.menu.find(item => item.key == "paiement") : {};
    return {
        data: getValidation(state),
        error: getError(state),
        validationPage: validationPage,
        menuList: getMenu(state),
        paymentData: getPaymentData(state),
        updateStatus: getUpdateStatus(state),
        user: getUser(state),
        basket: getBasket(state),
    };
};

const mapDispatchToProps = (dispatch): typeof ValidationActionCreators => {
    return bindActionCreators({
        ...ValidationActionCreators,
        destroyBasketStart: AddToCartActionCreators.destroyBasketStart
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Validation);
