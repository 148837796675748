import { OrderTypes, OrderActionCreators } from "./actions";
import { takeLatest, put, call, select } from "redux-saga/effects";
import axios from 'axios';
import {
    CREATE_ORDER,
    WEB_ORDER,
    UPDATE_ORDER,
    WEB_DISCOUNT_VALIDATE_NO_LOGIN
} from "../../../utils/constants/service";
import { getBasketData, getIsApprovedDiscount, getPromotionValue, isProductsInBarcode } from "../../../helpers/helpers";
import i18next from 'i18next';
import { BRAND_ID, PRIVATE_DISCOUNT, STORAGE_BASKET_INDEX } from "../../../utils/constants/variables";
import { getUser, getBrandDetail } from "../../../layouts/main/header/redux/selectors";
import { PaymentActionCreators } from "../../payment/redux/actions";
import { getBasket } from "../../addToCart/redux/selectors";
import { removeCouponDiscountFromBasket } from '../../../helpers/discountHelper';
import { AddToCartActionCreators } from '../../addToCart/redux/actions';
import { createPromotionData, getPromotionDiscountValue } from "../../../helpers/promotionDiscountHelper";
import { getUserInfo } from "../../login/redux/selectors";
import { getDiscount, getPrivateDiscount } from "./selectors";

export function* getOrderData() {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        const data = yield call(() => {
            return axios.get(WEB_ORDER, headers)
                .then(res => res.data)
        });

        yield put(OrderActionCreators.orderSuccess(data));
    } catch (e) {
        yield put(OrderActionCreators.orderFailure(e.message));
    }
}

export function* createOrder(params) {
    try {
        const user = yield select(getUser);
        const { email } = user
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            }
        };
        const data = yield call(() => {
            return axios.post(`${CREATE_ORDER}`, JSON.stringify(params.data), headers)
                .then(res => res)
        });
        yield put(OrderActionCreators.createOrderSuccess(data.data));
        params.callback && params.callback(data.data);
        // if (data.status === 200 && params.key === "create-order") {
        //     const incomingData = data.data
        //     const item = incomingData && incomingData.length ? incomingData.find(item => item.orderStatus === "CHE") : {};
        //     const orderId = item.orderId;
        //     yield put(PaymentActionCreators.amountStart(orderId, email));
        // }

    } catch (e) {
        yield put(OrderActionCreators.createOrderFailure(e.message));
    }
}

export function* getDiscountData(params) {
    const callback = params.callback || function() {};
    try {
        const basket = yield select(getBasket);
        const user = yield select(getUser);
        const detail = yield select(getBrandDetail);
        const webClient = yield select(getUserInfo)
        const basketData = getBasketData(basket);
        const privateSaleCode = localStorage.getItem(PRIVATE_DISCOUNT);
        const { discountCodes = [] } = webClient;
        const { price } = basketData;
        const webClientDiscountCode = discountCodes[discountCodes.length - 1];
        const weblcientId = params.webClientId || user.webClientId;
        const typeOfRequest = params.typeOfRequest;
        const webCLientIdName = weblcientId ? `&webClientId=${weblcientId}` : "";
        const promotion = detail && detail.promotion && detail.promotion[0];

        const headers = {
            headers: {
                Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
                'Content-Type': 'application/json',
            }
        };
        const data = yield call(() => {
            return axios.get(`${WEB_DISCOUNT_VALIDATE_NO_LOGIN}?discountCode=${params.data}&amount=${price}&brandId=${BRAND_ID}${webCLientIdName}`, headers)
                .then(res => res.data)
        });

        if (!data.valid) {
            yield put(OrderActionCreators.annullerDiscountStart());
            yield put(OrderActionCreators.validateDiscountFailure(i18next.t("product.basket.20")));
        }

        if (data && !data.valid && params.data === localStorage.getItem(PRIVATE_DISCOUNT)) {
            localStorage.removeItem(PRIVATE_DISCOUNT);
            yield put(OrderActionCreators.annullerPrivateDiscountSuccess());
        }

        if (privateSaleCode && data && data.couponType && data.couponType !== "PRIVATE_SALE") {
            yield put(OrderActionCreators.annullerDiscountStart());
            callback(data);
            return yield put(OrderActionCreators.validateDiscountFailure(i18next.t("product.basket.47")));
        }

        if (data && data.couponType === "PRIVATE_SALE" && data.valid) {
            localStorage.setItem(PRIVATE_DISCOUNT, data.discountCode);
            yield put(OrderActionCreators.annullerDiscountStart());
            callback(data);
            return yield put(OrderActionCreators.validatePrivateDiscountSuccess(data));
        }

        const promotionValue = getPromotionValue(promotion, basketData.totalPrice, basketData.totalQuantity);
        const promotionData = createPromotionData(promotion, basket, promotionValue);
        const discountedSum = getPromotionDiscountValue(promotionData, basket) || 0;

        if (discountedSum > 0 && !data.cumulative) {
            if (webClientDiscountCode === params.data) return;
            callback(data);
            return yield put(OrderActionCreators.validateDiscountSuccess({ isProductsInBarcode: true }, typeOfRequest));
        }

        const isValidCouponType = ["PERCENT"].includes(data.couponType);
        const isFixedCouponValid = data.couponType === "FIXED" ? +data.value < price : true;
        const isApprovedDiscount = getIsApprovedDiscount(basket, data)
        if (isValidCouponType || isFixedCouponValid) {
            yield put(AddToCartActionCreators.getBasketStart());
            yield put(OrderActionCreators.validateDiscountSuccess(data, typeOfRequest, isApprovedDiscount));
        } else {
            yield put(OrderActionCreators.annullerDiscountStart());
        }
        callback(data);
    } catch (e) {
        callback({});
        yield put(OrderActionCreators.validateDiscountFailure(e.message));
    }
}

export function* updateOrder(params) {
    const { orderId, orderStatus, email } = params.data;
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        const data = yield call(() => {
            return axios.put(`${UPDATE_ORDER}${orderId}/update-status?orderStatus=${orderStatus} `, {}, headers)
                .then(res => res.data)
        });

        yield put(OrderActionCreators.updateOrderSuccess({ data, orderStatus, orderId, email }));
        yield put(OrderActionCreators.getBasketStart());
    } catch (e) {
        yield put(OrderActionCreators.updateOrderFailure(e.message));
    }
}

export function* promotionDiscount(params) {
    const brandDetails = yield select(getBrandDetail);
    const basket = yield select(getBasket);
    const discount = yield select(getDiscount);
    const basketData = getBasketData(basket);
    const promotion = (brandDetails && brandDetails.promotion && brandDetails.promotion[0]) || {};
    const promotionValue = getPromotionValue(promotion, basketData.totalPrice, basketData.totalQuantity);
    if (promotion && (promotionValue > 0 || promotionValue.length)) {
        const data = createPromotionData(promotion, basket, promotionValue);
        const discountedSum = getPromotionDiscountValue(data, basket) || 0;

        if (discountedSum > 0) {
            if (discount && !discount.cumulative) yield put(OrderActionCreators.annullerDiscountStart());
            return yield put(OrderActionCreators.promotionDiscountSuccess(data));
        }
    }

    yield put(OrderActionCreators.annullerPromotionDiscountStart());
}

export function* annullerDiscount() {
    const newBasket = removeCouponDiscountFromBasket();
    localStorage.setItem(STORAGE_BASKET_INDEX, JSON.stringify(newBasket));

    yield put(AddToCartActionCreators.getBasketStart());
    yield put(OrderActionCreators.annullerDiscountSuccess());
}

export function* orderSaga() {
    yield takeLatest(OrderTypes.ORDER_START, getOrderData);
    yield takeLatest(OrderTypes.CREATE_ORDER_START, createOrder);
    yield takeLatest(OrderTypes.VALIDATE_DISCOUNT_START, getDiscountData);
    yield takeLatest(OrderTypes.UPDATE_ORDER_START, updateOrder);
    yield takeLatest(OrderTypes.PROMOTION_DISCOUNT_START, promotionDiscount);
    yield takeLatest(OrderTypes.ANNULLER_DISCOUNT_START, annullerDiscount);
}

export default orderSaga;
