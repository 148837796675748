import React, { useEffect, useMemo, useRef, useState } from "react"
import i18next from "i18next";
import AdyenCheckout from "@adyen/adyen-web";
import ResponseMessage from "../../../responseMessage/container";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button, Checkbox } from 'semantic-ui-react'
import { Link, RouteComponentProps } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { adyenLocalization, createWebOrderLineItems, decryptCryptr, getLangPrefix, getShippingCity, isProductsInBarcode } from "../../../../helpers/helpers";
import { getBrandDetail, getMenu, getUser } from "../../../../layouts/main/header/redux/selectors";
import { getAdyenConfiguration, getAmount, getEncriptAmount, getLoading } from "../../../payment/redux/selectors";
import {
  BRAND_ID, COUNTRY, DEFAULT_CURRENCY,
  DELIVERY_PRICE, FOREIGN_DELIVERY, LANGUAGE, ORDER_STATUS, STORE_DELIVERY, initialMessage,
  PRIVATE_DISCOUNT
} from "../../../../utils/constants/variables";
import { getCreateOrder, getDeliveryMethod, getDiscount, getPromotionDiscount } from "../../../basket/redux/selectors";
import { LocationSearchActionCreators } from "../../../locationSearchInput/redux/actions";
import { OrderActionCreators } from "../../../basket/redux/actions";
import { PaymentActionCreators } from "../../../payment/redux/actions";

import paypalImage from "../../../../assets/img/paypal.png";
import creditCardImage from "../../../../assets/img/creditcard.png";
import { TrackPurchase } from "../../../../helpers/ga4Tracker";
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
interface Props {
  address: any[];
  menuList: any[];
  loading: boolean;
  basketStore: any;
  basketData: any;
  brandDetail: any;
  amount: any;
  encriptAmount: string;
  deliveryMethod: string;
  basket: any;
  basketProductsBySizes: any[];
  shippingAddress: any[];
  billingAddress: any[];
  selectedShippingAddress: Record<string, any>;
  selectedBillingAddress: Record<string, any>;
  discount: any;
  promotionDiscount: any;
  adyenConfiguration: any;
  order: any;
  user: any;
  dispatch: (...args) => void;
}

export const Payment: React.FC<Props & RouteComponentProps> = ({
  adyenConfiguration,
  menuList,
  basketData,
  basketStore,
  discount,
  promotionDiscount,
  brandDetail,
  basket,
  deliveryMethod,
  shippingAddress,
  billingAddress,
  selectedShippingAddress,
  selectedBillingAddress,
  basketProductsBySizes,
  order,
  history,
  dispatch
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [messageOptions, setMessageOptions] = useState<Record<string, any>>({ ...initialMessage });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showInfoPopUp, setShowInfoPopup] = useState<boolean>(false);
  const [isPayPalLoading, setIsPayPalLoading] = useState<boolean>(false);

  const payRef = useRef<HTMLDivElement>(null);
  const prevStore = useRef<Record<string, any>>({});
  const adyenDropIn = useRef<any>();
  const isPaymentCheckedAllowed = true;
  const createdOrder = order[0] || {};
  const createdOrderId = createdOrder.orderId || null;
  const storeDelivery = basketStore.storeDelivery;
  const selectedStore = basketStore.StoreInfo || {};
  const isShippingDisable = storeDelivery !== "STO" ? !selectedShippingAddress.clientAddressId : false;
  const disabled = !isChecked || !createdOrderId || !selectedBillingAddress.clientAddressId || isShippingDisable;

  const cgvMenu = useMemo(() => {
    return menuList.find(item => item.altUrl1 === "cgv") || {};
  }, [menuList])

  useEffect(() => {
    if (basketData.totalQuantity && isChecked) hendleChangeCeckBoxCheked(null, { checked: false });
    if (basketData.totalQuantity === 0) dispatch(OrderActionCreators.annullerDiscountStart());
  }, [basketData.totalQuantity])

  useEffect(() => {
    if (prevStore.current.discount === null && discount && isChecked) {
      hendleChangeCeckBoxCheked(null, { checked: false });
    }

    if (prevStore.current.discount && discount === null && isChecked) {
      hendleChangeCeckBoxCheked(null, { checked: false });
    }
    if (prevStore.current.discount && discount && discount.value && (prevStore.current.discount.couponType !== discount.couponType
      || prevStore.current.discount.value !== discount.value) && isChecked) {
      setIsChecked(false);
    }

    return () => {
      prevStore.current.discount = discount;
    }
  }, [discount])

  const hendleChangeCeckBoxCheked = (_, input) => {
    if (input.checked) handleCreateOrder();
    else if (adyenDropIn.current && adyenDropIn.current.unmount) adyenDropIn.current.unmount();

    setIsChecked(input.checked);
  }

  const showMessage = (status: string, text: string) => {
    setMessageOptions({
      status: status,
      text: text,
      isVisible: true
    });

    setTimeout(() => {
      setMessageOptions({ ...initialMessage });
    }, 7000);
  }

  const adyenCall = async (adyenSession) => {
    const configuration = {
      ...adyenConfiguration,
      session: { ...adyenSession },
      onPaymentCompleted: (result) => {
        if (result.resultCode === "Refused" || result.resultCode === "Error") {
          console.log(result, 1);
          // history.push(`${getLangPrefix()}/failed`);
        } else {
          history.push(`${getLangPrefix()}/accepted`);
        }
      },
      onError: (error) => {
        if (!error.toString().includes("CANCEL")) {
          console.log(error, 2);
          // history.push(`${getLangPrefix()}/failed`);
        }
      },
    };

    const checkout = await AdyenCheckout(configuration);

    if (!payRef.current) return;
    adyenDropIn.current = checkout.create("dropin", {
      onReady: () => {
        // Event to change selected payment method on click Paypal button
        const cardButton = document.querySelector(
          ".adyen-checkout__payment-method--card"
        );
        if (cardButton) {
          cardButton.addEventListener("click", () => {
            localStorage.setItem("selectedPaymentMethod", "card");
          });
        }

        // Event to change selected payment method on click Paypal button
        const paypalButton = document.querySelector(
          ".adyen-checkout__payment-method--paypal"
        );
        if (paypalButton) {
          paypalButton.addEventListener("click", () => {
            localStorage.setItem("selectedPaymentMethod", "paypal");
          });
        }
      },
    }).mount(payRef.current);
    setIsLoading(false);
  }
  const isAtLeastOneNotPartOfCoupon = isProductsInBarcode()

  const handleSession = (orderId) => {
    const { totalPrice } = basketData;

    if (!totalPrice) return;

    const body = {
      orderId,
      country: COUNTRY,
      currency: DEFAULT_CURRENCY,
      returnUrl: "/",
      shopperLocale: adyenLocalization(brandDetail.lowerlanguage),
    };

    dispatch(PaymentActionCreators.adyenSessionStart(body, (adyenSession) => {
      adyenSession && adyenCall(adyenSession);
    }));
  };

  const createOrder = () => {
    const { webOrderLineItems, key } = createWebOrderLineItems(basket, basketData);
    const privateDiscountCode = localStorage.getItem(PRIVATE_DISCOUNT);

    if (storeDelivery !== "STO" && !shippingAddress.length) {
      hendleChangeCeckBoxCheked(null, { checked: false });
      return showMessage('failed', i18next.t('product.basket.delivery.4'));
    }
    if (
      FOREIGN_DELIVERY === "0" &&
      STORE_DELIVERY === "1" &&
      storeDelivery !== "STO" &&
      selectedShippingAddress.countryCode &&
      selectedShippingAddress.countryCode.toUpperCase() !== LANGUAGE.toUpperCase()
    ) {
      hendleChangeCeckBoxCheked(null, { checked: false });
      return showMessage('failed', i18next.t('payment.12'));
    }

    // if product price less then shippingMin then add delivery price
    if (basketData.price < DELIVERY_PRICE && webOrderLineItems.length) {
      webOrderLineItems.push({
        orderLineId: key,
        currency: DEFAULT_CURRENCY,
        itemType: "FREIGHT_COST"
      })
    }

    const discountCode = privateDiscountCode
      ? privateDiscountCode : discount && discount.discountCode
        ? discount.discountCode : ""

    const shippingAddressId = deliveryMethod === "storeDelivery" && storeDelivery === "STO"
      ? (shippingAddress.find(item => item.isDefault === true) || {}).clientAddressId
      : selectedShippingAddress.clientAddressId
        ? selectedShippingAddress.clientAddressId
        : (shippingAddress.find(item => item.isDefault === true) || {}).clientAddressId

    const billingAddressId = selectedBillingAddress
      ? selectedBillingAddress.clientAddressId
      : (billingAddress.find(item => item.isDefault === true) || {}).clientAddressId

    setMessageOptions({ ...initialMessage });

    if (webOrderLineItems.length && billingAddressId) {
      const storeCode = selectedStore.storeCode || "";
      const body = {
        billingAddressId,
        brandId: BRAND_ID,
        ...(!isAtLeastOneNotPartOfCoupon && { discountCode }),
        orderId: null,
        country: COUNTRY,
        orderStatus: ORDER_STATUS.CHE,
        shippingAddressId,
        storeCode,
        webOrderLineItems,
        browserId: decryptCryptr("browserId"),
        ...(promotionDiscount && { promotionRuleId: promotionDiscount.promotionRuleId })
      };

      setIsLoading(true);
      dispatch(OrderActionCreators.createOrderStart(body, (order) => {
        if (order && order.length) handleSession(order[0].orderId)
      }));
    } else if (!billingAddressId) {
      hendleChangeCeckBoxCheked(null, { checked: false });
      return showMessage('failed', i18next.t("addToCart.6"));
    } else {
      hendleChangeCeckBoxCheked(null, { checked: false });
      return showMessage('failed', i18next.t("addToCart.5"));
    }
  }

  const handleCreateOrder = (callDirectly = false) => {
    if (STORE_DELIVERY === "0") return createOrder()
    if (callDirectly) return createOrder();

    basketProductsBySizes.length && dispatch(LocationSearchActionCreators.clickAndCollectStoresStart({
      location: getShippingCity(),
      body: {
        basketLineItems: basketProductsBySizes.map((product) => ({ productId: product.productId, quantity: product.quantity }))
      },
      queryParams: storeDelivery === 'DIS'
        ? { shipment: true }
        : { consignment: true }
    }, (res) => {
      if (!res || !res.length) {
        setIsChecked(false)
        return setShowInfoPopup(true)
      }
      try { createOrder() } catch (e) { console.log(e) }
    }))
  };

  return (
    <div className="peymant-content-item peymant-content-item-code mb_0">
      <h4 className={'text-uppercase'}>{i18next.t('payment.1')}</h4>
      <Checkbox
        onChange={hendleChangeCeckBoxCheked}
        className="mainCheckbox"
        disabled={!isPaymentCheckedAllowed}
        checked={isChecked}
        label={
          <label>{i18next.t('payment.2')}
            <Link className={'main-link ml_15'}
              to={getLangPrefix() + cgvMenu.canonicalUrl} target="_blank" >
              {cgvMenu.label}
            </Link>
          </label>
        }
      />
      {messageOptions.isVisible && (
        <ResponseMessage
          status={messageOptions.status}
          errorText={messageOptions.text}
          successText={messageOptions.text}
        />
      )}
      {!disabled && (
        <div id="dropin-container" ref={payRef} />
      )}
      {console.log(isChecked)}
      <div>
        {(!isChecked || isLoading) && (
          <div className="payment-buttons">
            <Button
              loading={isLoading}
              className="credit-card-button"
            >
              {!isLoading && (
                <div className="inner-credit-card-button ">
                  <img
                    className="credit-card-button-image"
                    src={creditCardImage}
                    alt="credit card"
                  />
                  <span>{i18next.t("payment.15")}</span>
                </div>
              )}
            </Button>
            <Button
              loading={isLoading}
              className="paypal-button"
            >
              {!isLoading && (
                <img
                  className="paypal-button-image"
                  src={paypalImage}
                  alt="paypal"
                />
              )}
            </Button>
          </div>
        )}
        {createdOrderId && (
          <div ref={payRef}></div>
        )}
      </div>
      {showInfoPopUp && (
        <ConfirmPopup
          handleCancel={() => setShowInfoPopup(false)}
          text={i18next.t('modal.2')}
          title={""}
          footer={false}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any): Partial<Props> => {
  return {
    discount: getDiscount(state),
    promotionDiscount: getPromotionDiscount(state),
    menuList: getMenu(state),
    loading: getLoading(state),
    brandDetail: getBrandDetail(state),
    deliveryMethod: getDeliveryMethod(state),
    amount: getAmount(state),
    encriptAmount: getEncriptAmount(state),
    order: getCreateOrder(state),
    adyenConfiguration: getAdyenConfiguration(state),
    user: getUser(state),
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return { dispatch }
}

export const PaymentBlock = connect(mapStateToProps, mapDispatchToProps)(withRouter(Payment));
